.contacts{
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    animation: startAnim .7s forwards;
}
@keyframes startAnim{
    from{opacity: 0;}
    to{opacity: 1;}
}
.contacts_top{
    width: 85%;
    height: 60%;
    display: flex;
    justify-content: space-between;
}
#map{
    width: 43vw;
    height: 100%;
    border-radius: 10px;
}
.contacts_cont{
    width: 48%;
    background-color: #D2B746dd;
    border-radius: 10px;
}
.contacts_cont h2{
    font-size: 30px;
    color: whitesmoke;
    font-family: "Jura";
    font-weight: 700;
    padding-left: 10px;
    margin: 15px 0;
}
.contacts_cont ul{
    width: 100%;
    height: calc(100% - 90px);
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}
.contacts_cont ul li{
    padding: 10px 15px;
    width: 90%;
    list-style-type: none;
    background-color: #0A7F29;
    display: flex;
    align-items: center;
    border-radius: 0 10px 10px 0;
}
.contacts_cont ul li img{
    width: 45px;
}
.contacts_cont ul li span{
    color: whitesmoke;
    font-family: "Jura";
    font-size: 23px;
    font-weight: 700;
    margin-left: 15px;
}
.link{
    color: whitesmoke;
    text-decoration: underline;
}

.cont_callback{
    background-color: #D2B746dd;
    border-radius: 10px;
    width: 70%;
    height: 40%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    padding-bottom: 20px;
}
.cont_callback .title{
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
}
.cont_callback .title h2{
    color: whitesmoke;
    font-size: 30px;
    font-family: "Jura";
    font-weight: 700;
    margin: 0;
    padding-left: 30px;
}
.cont_callback .title .send{
    margin-left: 15px;
    border: none;
    background-color: #0A7F29;
    color: whitesmoke;
    font-size: 25px;
    font-family: "Jura";
    font-weight: 700;
    padding: 7px 15px;
    border-radius: 5px;
    transition-duration: .2s;
}
.cont_callback .title .send:hover{
    cursor: pointer;
    background-color: #0A7F29dd;
}
.cont_callback .name{
    background-color: #0A7F29;
    border: none;
    color: whitesmoke;
    width: 50%;
    font-size: 25px;
    font-family: "Jura";
    padding: 10px 30px;
    border-radius: 0 7px 7px 0;
}
.cont_callback .name:nth-child(3){
    width: 60%;
}
.cont_callback .name:focus{
    outline: none;
}
.cont_callback .name::-webkit-input-placeholder{
    color: whitesmoke;
}
.cont_callback .order{
    background-color: #0A7F29;
    border: none;
    color: whitesmoke;
    width: 80%;
    font-size: 25px;
    font-family: "Jura";
    padding: 10px 30px;
    border-radius: 0 7px 7px 0;
    resize: none;
}
.cont_callback .order:focus{
    outline: none;
}
.cont_callback .order::-webkit-input-placeholder{
    color: whitesmoke;
}
.cont_callback .order::-webkit-scrollbar{
    background-color: transparent;
}

@media(max-width: 1280px){
    .contacts_top{
        width: 95%;
        height: 60%;
        display: flex;
        justify-content: space-between;
    }
    .contacts_cont h2{
        font-size: 25px;
    }
    .contacts_cont ul li{
        padding: 5px 15px;
        width: 90%;
    }
    .contacts_cont ul li img{
        width: 32px;
    }
    .contacts_cont ul li span{
        font-size: 17px;
    }
}
@media(max-height: 800px){
    .contacts_top{
        width: 95%;
        height: 80%;
        display: flex;
        justify-content: space-between;
    }
}