.contacts_mob{
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.contacts_mob .contacts_top{
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.contacts_mob #map{
    width: 95vw;
    height: 40%;
    border-radius: 10px;
}
.contacts_mob .contacts_cont{
    margin-top: 10px;
    width: 95%;
    height: 50%;
    background-color: #D2B746;
    border-radius: 10px;
}
.contacts_mob .contacts_cont h2{
    font-size: 25px;
    color: whitesmoke;
    font-family: "Jura";
    font-weight: 700;
    padding-left: 10px;
    margin: 5px 0;
}
.contacts_mob .contacts_cont ul{
    width: 100%;
    height: calc(100% - 50px);
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}
.contacts_mob .contacts_cont ul li{
    padding: 5px 10px;
    width: 90%;
    list-style-type: none;
    background-color: #0A7F29;
    display: flex;
    align-items: center;
    border-radius: 0 10px 10px 0;
}
.contacts_mob .contacts_cont ul li img{
    width: 35px;
}
.contacts_mob .contacts_cont ul li span{
    color: whitesmoke;
    font-family: "Jura";
    font-size: 17px;
    font-weight: 700;
    margin-left: 15px;
}
.contacts_mob .link{
    color: whitesmoke;
    text-decoration: underline;
}