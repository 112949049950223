
.sortCard{
    position: absolute;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #00000066;
    height: calc(100vh - 132px);
    animation: startAnim .4s forwards
}
@keyframes startAnim{
    from{
        opacity: 0;
        left: -100%
    }
    to{
        opacity: 1;
        left: 0
    }
}
.sortCard.closing{
    animation: closeSorts .4s forwards;
}
@keyframes closeSorts{
    from{
        left: 0;
        opacity: 1;
    }
    to{
        left: -100%;
        opacity: 0;
    }
}
.sortCard .main_wind{
    width: 65%;
    height: 85%;
    background-color: #D2B746;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 10px;
}
.sortCard .main_wind .top{
    position: absolute;
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sortCard .main_wind .top .name{
    color: whitesmoke;
    font-family: "Jura";
    font-size: 35px;
    margin: 0;
    background-color: #0A7F29;
    padding: 0 50px;
    padding-bottom: 5px;
    border-radius: 0 0 5px 7px;
}
.sortCard .main_wind .top .close{
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    transition-duration: .4s;
}
.sortCard .main_wind .top .close:hover{
    cursor: pointer;
    background-color: #af9b4a;
}
.sortCard .main_wind .top .close::before,
.sortCard .main_wind .top .close::after{
    position: absolute;
    content: "";
    background-color: #C40B0B;
    width: 37px;
    height: 5px;
    border-radius: 2.5px;
}
.sortCard .main_wind .top .close::before{
    rotate: 45deg;
}
.sortCard .main_wind .top .close::after{
    rotate: -45deg;
}
.sortCard .main_wind .info{
    margin-top: 70px;
    width: 98%;
    height: calc(100% - 85px);
    overflow-y: scroll;
}
.sortCard .main_wind .info::-webkit-scrollbar{
    background-color: transparent;
    width: 10px;
}
.sortCard .main_wind .info::-webkit-scrollbar-thumb{
    background-color: #0A7F29;
    border-radius: 5px;
}
.sortCard .main_wind .info .authors{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 250px;
}
.sortCard .main_wind .info .img{
    height: 100%;
    border-radius: 7px;
    margin-left: 20px;
}
.sortCard .main_wind .info .authors .names{
    margin-left: 40px;
    height: 100%;
    width: 400px;
}
.sortCard .main_wind .info .authors .names span{
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    font-size: 25px;
    font-family: "Jura";
    color: whitesmoke;
    font-weight: 700;
}
.sortCard .main_wind .info .authors .names ul{
    height: calc(100% - 30px);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;
}
.sortCard .main_wind .info .authors .names ul li{
    list-style-type: none;
    /* width: 35%; */
    font-size: 23px;
    font-family: "Jura";
    color: whitesmoke;
    /* border: 1px solid whitesmoke; */
    /* text-align: center; */
    font-weight: 600;
}

.sortCard .main_wind .info .base_block{
   font-family: "Jura";
   color: whitesmoke; 
   margin-top: 20px;
}
.sortCard .main_wind .info .base_block .title{
    background-color: #0A7F29;
    width: fit-content;
    font-weight: 700;
    padding: 3px 20px;
    border-radius: 0 7px 7px 0; 
    margin: 0;
    display: flex;
    align-items: center;
}
.sortCard .main_wind .info .base_block .title h2{
    margin: 0;
    font-size: 30px;
}
.sortCard .main_wind .info .base_block .title img{
    width: 30px;
    margin-left: 15px;
}
.sortCard .main_wind .info .base_block p{
    margin: 0;
    margin-top: 15px;
    width: calc(100% - 40px);
    padding-left: 20px;
    font-size: 23px;
    font-weight: 700;
    text-align: justify;
}
.sortCard .main_wind .info .base_block .block_list{
    margin: 0;
    margin-top: 10px;
    padding-left: 30px;
    width: calc(100% - 60px);
}
.sortCard .main_wind .info .base_block .block_list li{
    font-size: 25px;
    font-weight: 700;
}
.sortCard .main_wind .info .base_block .block_list li:not(:last-child){
    margin-bottom: 5px;
}
.sortCard .main_wind .info .base_block .block_list li .key_word{
    text-decoration: underline;
}
@media(max-width: 1300px){
    .sortCard .main_wind{
        width: 90%;
        height: 85%;
    }
    .sortCard .main_wind .top{
        width: 90%;
    }
    .sortCard .main_wind .info .authors .names{
        width: 500px;
    }
}
@media(max-height: 600px){
    .sortCard{
        height: calc(100vh - 92px);
    }
}