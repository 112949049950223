.footer{
    position: absolute;
    z-index: 100;
    width: 100%;
    background-color: #535442;
    color: whitesmoke;
    font-size: 30px;
    font-family: "Jura";
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -10px 10px #535442;
}
.footer span{
    margin: 3px 0
}