.sorts{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: startAnim .7s forwards;
}
@keyframes startAnim{
    from{opacity: 0;}
    to{opacity: 1;}
}
.sorts .list{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-evenly;
}
.sorts .list li{
    list-style-type: none;
    width: 25%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sorts .list li div{
    /* width: 80%; */
    padding: 15px 15px;
    padding-bottom: 10px;
    border-radius: 10px;
    transition-duration: .4s;
    height: fit-content;
    width: min-content;
    background-color: #D2B746;
    background-image: linear-gradient(transparent 50%, #af9b4a 50%);
    background-size: 100% 200%;
    background-repeat: no-repeat;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.sorts .list li div:hover{
    cursor: pointer;
    background-position: 0 100%;
}
.sorts .list li div:hover img{
    width: 335px;
}
.sorts .list li div img{
    width: 300px;
    border-radius: 10px;
    transition-duration: .4s;
}
.sorts .list li div .name{
    width: 100%;
    text-align: center;
    font-size: 38px;
    font-weight: 700;
    font-family: "Jura";
    color: whitesmoke;
    margin: 0;
}

@media(max-width: 1280px){
    .sorts .list li div img{
        width: 250px;
    }
    .sorts .list li div:hover img{
        width: 280px;
    }
}
@media(max-height: 800px){
    .sorts .list li div img{
        width: 230px;
    }
    .sorts .list li div:hover img{
        width: 260px;
    }
}
@media(max-height: 600px){
    .sorts .list li div .name{
        font-size: 30px;
    }
    .sorts .list li div img{
        width: 170px;
    }
    .sorts .list li div:hover img{
        width: 200px;
    }
}