.sorts_mob{
    width: 100%;
    height: calc(100vh - 200px);
}
.sorts_mob .list{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin: 0;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
    transition-duration: .4s;
}
.sorts_mob .list.hidden{
    opacity: 0;
}
.sorts_mob .list li{
    list-style-type: none;
    background-color: #D2B746;
    width: 45%;
    min-height: 25%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-around;
    padding: 10px 0;
    padding-bottom: 5px;
}
.sorts_mob .list li:not(:last-child){
    margin-bottom: 10px;
}
.sorts_mob .list li img{
    width: 85%;
    border-radius: 5px;
}
.sorts_mob .list li .name{
    margin: 0;
    font-family: "Jura";
    font-size: 25px;
    font-weight: 700;
    color: whitesmoke;
    width: 100%;
    text-align: center;
}

@media(max-height: 720px){
    /* .sorts_mob .list li img{
        width: 75%;
        border-radius: 5px;
    } */
}