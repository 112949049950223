.table_mob{
    position: absolute;
    top: 12.5%;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 165px);
    animation: startAnim .4s forwards
}
@keyframes startAnim{
    from{
        opacity: 0;
        left: -100%
    }
    to{
        opacity: 1;
        left: 0
    }
}
.table_mob.closing{
    animation: closeTable .4s forwards;
}
@keyframes closeTable{
    from{
        left: 0;
        opacity: 1;
    }
    to{
        left: -100%;
        opacity: 0;
    }
}

.table_mob .main_wind{
    width: 100%;
    height: 97%;
    padding: 0;
    background-color: #D2B746;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    border-radius: 10px;
}
.table_mob .main_wind .top{
    /* position: absolute; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.table_mob .main_wind .top .head{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.table_mob .main_wind .top .icon{
    width: 30px;
}
.table_mob .main_wind .top .title{
    font-family: "Jura";
    color: whitesmoke;
    background-color: #0A7F29;
    text-align: center;
    margin: 0;
    font-size: 20px;
    padding: 5px 5px;
    border-radius: 0 0 5px 0;
}
.table_mob .main_wind .top .search{
    background-color: #0A7F29;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    margin-top: 10px;
    border-radius: 5px;
}
.table_mob .main_wind .top .search input{
    font-size: 15px;
    font-family: "Jura";
    font-weight: 700;
    text-align: center;
    border: 2px solid whitesmoke;
    color: whitesmoke;
    background-color: transparent;
    padding: 5px 0;
    border-radius: 5px;
}
.table_mob .main_wind .top .search input::-webkit-input-placeholder{
    color: whitesmoke;
}
.table_mob .main_wind .top .search input:focus{
    outline: none;
}
.table_mob .main_wind .top .search button{
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    transition-duration: .2s;
}
.table_mob .main_wind .top .search button:hover{
    cursor: pointer;
    background-color: #0a6a24;
}
.table_mob .main_wind .top .search button img{
    width: 30px;
}
.table_mob .main_wind .top .close{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    transition-duration: .4s;
    margin-right: 10px;
    margin-left: 10px;
}
.table_mob .main_wind .top .close:hover{
    cursor: pointer;
    background-color: #af9b4a;
}
.table_mob .main_wind .top .close::before,
.table_mob .main_wind .top .close::after{
    position: absolute;
    content: "";
    background-color: #C40B0B;
    width: 27px;
    height: 5px;
    border-radius: 2.5px;
}
.table_mob .main_wind .top .close::before{
    rotate: 45deg;
}
.table_mob .main_wind .top .close::after{
    rotate: -45deg;
}

.table_mob .main_wind .main_table{
    width: 95%;
    margin-top: 10px;
    height: calc(100% - 115px);
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.table_mob .main_wind .main_table.active{
    /* margin-top: 100px; */
}
.table_mob .main_wind .main_table::-webkit-scrollbar{
    background-color: transparent;
    width: 7px;
}
.table_mob .main_wind .main_table::-webkit-scrollbar-thumb{
    background-color: #0a6a24;
    border-radius: 3.5px;
}
.table_mob .main_wind .main_table table{
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Jura';
    background-color: #FFF1B8;
    border-radius: 5px;
}
.table_mob .main_wind .main_table table thead{
    background-color: #FFF1B8;
}
.table_mob .main_wind .main_table table thead tr{
    padding: 0;
    margin: 0;
    background-color: #FFF1B8;
}
.table_line{transition-duration: .4s;}
.table_line.active{
    background-color: #0a6a24;
    color: whitesmoke;
}
.table_mob .main_wind .main_table table thead tr th{
    background: #FFF1B8;
    /* border-right: 1px solid black; */
    border-bottom: 1px solid black;
    padding: 2px;
    margin: 0;
    font-size: 8px;
    font-weight: 700;
}
.table_mob .main_wind .main_table table thead tr th:last-child{
    border-right: none;
}
.table_mob .main_wind .main_table table tbody tr:nth-child(2n-1) th{
    background-color: #0a6a2466;
}
.table_mob .main_wind .main_table table tbody tr:last-child th:first-child{
    border-radius: 0 0 0 5px;
}
.table_mob .main_wind .main_table table tbody tr:last-child th:last-child{
    border-radius: 0 0 5px 0;
}
.table_mob .main_wind .main_table table tbody tr th{
    /* border-right: 1px solid black;
    border-bottom: 1px solid black; */
    margin: 0;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 2px;
}
.table_mob .main_wind .main_table table tbody tr th:last-child{
    border-right: none;
}
.table_mob .main_wind .main_table table tbody tr:last-child th{
    border-bottom: none;
}
