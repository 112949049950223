.pc{
    width: 100%;
    height: 100%;
}
.pc .back{
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}
.pc .main_back{
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    background-color: #6f6330;
}
.pc .back.active{
    opacity: 1;
}
.pc .back.img_0{
    background-image: url("./img/back1.jpg");
}
.pc .back.img_1{
    background-image: url("./img/back2.jpg");
}
.pc .back.img_2{
    background-image: url("./img/back3.jpg");
}
.pc .back.img_3{
    background-image: url("./img/back4.jpg");
}
.pc .back.img_4{
    background-image: url("./img/back5.jpg");
}
.pc .back.img_5{
    background-image: url("./img/back6.jpg");
}
.pc .back.img_6{
    background-image: url("./img/back7.jpg");
}

.pc .main{
    padding-top: 90px;
    height: calc(100vh - 132px);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}